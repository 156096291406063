import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useQuery } from "@tanstack/react-query";

import { Button, Typography, Row, Space, Card, Image, Modal, List, message, Carousel } from "antd";
import { PlusCircleOutlined, RightOutlined, CheckCircleTwoTone } from '@ant-design/icons';

import { getTopUpSession, getUser, removeTopUpSession, setTopUpSession } from "../../utils/AuthService";
import { getTopUpList, getPaymentUrl } from './api/TopupAPI'
import { TopUpModel } from "./type/topupType";

import plusIcon from "../../assets/svg/plus-circle.svg";
import minusIcon from "../../assets/svg/minus-circle.svg";

const TopUp = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { Text } = Typography;

  const playerData = getUser();

  const [isLaptopMode, setIsLaptopMode] = useState(window.innerWidth > 768);
  const [isPromotionModalOpen, setIsPromotionModalOpen] = useState(false);
  const [isBuyPromotionModalOpen, setIsBuyPromotionModalOpen] = useState(false);
  const [isDiamondModalOpen, setIsDiamondModalOpen] = useState(false);
  const [isBuyDiamondModalOpen, setIsBuyDiamondModalOpen] = useState(false);
  const [topupQty, setTopupQty] = useState(1);

  const [topupProductId, setTopupProductId] = useState('')
  const [diamondValue, setDiamondValue] = useState(0);
  const [diamondIcon, setDiamondIcon] = useState('../../assets/images/hope-coin-large.png')

  // * Promotion
  const showPromotionModal = (productId: string) => {
    setTopupProductId(productId);
    setIsPromotionModalOpen(true);
  };

  const closePromotionModal = () => {
    setIsPromotionModalOpen(false);
  };

  const handleBuyPromotionModal = async () => {
    setTopUpSession("promotion", diamondIcon, diamondValue, topupQty)

    const { paymentUrl } = await getPaymentUrl(topupProductId, 0).then(result => result.data);
    window.location.replace(paymentUrl);
  };

  const closeBuyPromotionModal = () => {
    removeTopUpSession();
    setIsBuyPromotionModalOpen(false);
  };
  // * End Promotion

  // * Diamond
  const showCoinModal = (productId: string) => {
    setTopupProductId(productId);
    setIsDiamondModalOpen(true);

    const selectedItem = hopeCoin?.hopeCoins?.find(item => item.productId === productId);
    setDiamondValue(selectedItem?.effect || 0)
    setDiamondIcon(selectedItem?.largeIconUrl || '')
  };

  const closeCoinModal = () => {
    setIsDiamondModalOpen(false);
  };

  const handleBuyCoinModal = async () => {
    setTopUpSession("diamond", diamondIcon, diamondValue,topupQty)

    const { paymentUrl } = await getPaymentUrl(topupProductId, topupQty).then(result => result.data);
    window.location.replace(paymentUrl);
  };

  const closeBuyCoinModal = () => {
    removeTopUpSession();
    setIsBuyDiamondModalOpen(false);
  };

  const handleIncreaseAmount = () => {
    setTopupQty(topupQty + 1);
  };

  const handleDecreaseAmount = () => {
    if (topupQty > 1) {
      setTopupQty(topupQty - 1);
    }
  };
  // * End Diamond

  const { 
    data: hopeCoin, 
    isLoading, 
    isError,
  } = useQuery<TopUpModel, Error>({
    queryKey: ["hopeCoin"],
    queryFn: () => getTopUpList(),
    retry: false,
    onError: (error) => {
      message.error(error.message);
    },
  });

  const uriQuery = new URLSearchParams(useLocation().search);
  const statusID = uriQuery.get('status_id');

  useEffect(() => {
    if (statusID && String(statusID) === '1') {
      const topupValue = getTopUpSession();

      if (topupValue.type === 'promotion' ) {
        setIsBuyPromotionModalOpen(true);
      } else if (topupValue.icon && topupValue.value && topupValue.quantity) {
        setTopupQty(Number(topupValue.quantity))
        setDiamondValue(Number(topupValue.value))
        setDiamondIcon(topupValue.icon)

        setIsDiamondModalOpen(false);
        setIsBuyDiamondModalOpen(true);
      }
    } else if (statusID && String(statusID) === '0') {
      removeTopUpSession();
      message.error(t('failPurchase'));
    }
    
    const handleResize = () => {
      setIsLaptopMode(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, [])

  return (
    <div>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Space size="small" direction="vertical" style={{ width: "100%" }}>
          <Row>
            <Space size="middle">
              <PlusCircleOutlined />
              <Text style={{ fontSize:"16px" }}>
                {t('topUp')}
              </Text>
            </Space>
          </Row>
          <Row>
            <Text style={{ fontSize:"12px" }}>
              {t('topUpInfo')}
            </Text>
          </Row>
          { playerData && playerData!.firstBonusAvail === true ?
            <Row style={{ justifyContent: "center" }}>
              <Carousel autoplay={true} autoplaySpeed={5000}
                  style={{ 
                    background: "var(--orange-gradient, linear-gradient(114deg, #F58C31 -9.08%, #F76C1C -9.07%, #F68C34 28.36%, #FAB44C 70.57%, #F9D152 105.05%))",
                    padding: "10px",
                    borderRadius: "10px",
                  }}
              >
                {hopeCoin?.promotions.map((listData, index) => (
                  <div key={index}>
                    <List
                      loading={isLoading}
                      dataSource={[listData]}
                      renderItem={(item) => (
                        <List.Item style={{ padding: "0 0 15px 0"}}>
                          <Card 
                            style={{ 
                              width: "100%",
                              backgroundColor: "transparent",
                            }}
                            bodyStyle={isLaptopMode ? 
                              {
                                padding: "0px",
                                height: "100px",
                              } :
                              {
                                padding: "0px",
                                height: "70px",
                              }
                            }
                            onClick={() => showPromotionModal(item.productId)}
                          >
                            <Image style={{ borderRadius: '5px' }} src={item.largeIconUrl} height={isLaptopMode ? 100 : 70} width={"100%"} preview={false} />
                          </Card>
                        </List.Item>
                      )}
                    />
                  </div>
              ))}
              </Carousel>
            </Row>
            : null
          }
        </Space>
        <Row>
          <Card 
            style={{ 
              width: "100%", 
              background: "var(--orange-gradient, linear-gradient(114deg, #F58C31 -9.08%, #F76C1C -9.07%, #F68C34 28.36%, #FAB44C 70.57%, #F9D152 105.05%))",
              textAlign:"center", 
            }}
            bodyStyle={{
              padding: 15,
            }}
          >
            <Space direction="vertical" size={"middle"} style={{ width: "100%", paddingInline: "5px" }}>
              <Text style={{ fontSize:"16px", fontWeight:800 }}>
                {t('diamond')}
              </Text>
              <List
                grid={{ 
                  gutter: 8, 
                  xs: 3,
                  sm: 3,
                  md: 6,
                }}
                loading={isLoading}
                dataSource={hopeCoin?.hopeCoins}
                renderItem={(item) => (
                  <List.Item>
                    <Card 
                      style={{ 
                        background: "rgba(255, 214, 185, 0.50)",
                        textAlign:"center",
                      }}
                      bodyStyle={ isLaptopMode ?
                        { padding: "24px" } :
                        { padding: "10px 0" }
                      }
                      onClick={() => showCoinModal(item.productId)}
                    >
                      <Space direction="vertical" size={"middle"}>
                        <Text style={{ fontSize: "16px", fontWeight: 800 }}>
                          {item.effect}
                        </Text>
                        <Image src={item.largeIconUrl} height={isLaptopMode ? 50 : 40} preview={false} />
                        <Text style={{ fontSize: isLaptopMode ? "18px" : "14px", fontWeight: 800 }}>
                          RM {item.price.toFixed(2).padStart(5, '0')}
                        </Text>
                      </Space>
                    </Card>
                  </List.Item>
                )}
              />
            </Space>
          </Card>
        </Row>
        <Row style={{ justifyContent: "center" }}>
          <Button type="text" onClick={()=> navigate(`/top-up/history`)}>
            <Text style={{fontSize: "16px", fontWeight: 600, color: "#FFFFFF"}}>
              {t('viewTopUpHistory')} <RightOutlined  />
            </Text>
          </Button>
        </Row>
      </Space>

      {/* // * Promotion Modal */}
      <Modal 
        centered={true} 
        open={isPromotionModalOpen} 
        maskClosable={true}
        footer={[
          <Row style={{ justifyContent: "center" }}>
            <Button block className="button-confirm" onClick={handleBuyPromotionModal}>
              <Text style={{fontSize: "18px", fontWeight: 700, color: "#F77725" }}>
                {t('confirm')}
              </Text>
            </Button>
          </Row>
        ]}
        onCancel={closePromotionModal}
      >
        <Row style={{ justifyContent: "center", margin: "30px 0" }}>
            <Text style={{fontSize: "20px", fontWeight: 700 }}>{t('promoConfirm')}</Text>
        </Row>
      </Modal>
      {/* // * End Promotion Modal */}
      
      {/* // * Success Buy Promotion Modal */}
      <Modal 
        centered={true} 
        open={isBuyPromotionModalOpen} 
        maskClosable={true}
        footer={[]}
        onCancel={closeBuyPromotionModal}
      >
        <Row style={{ justifyContent: "center", margin: "30px 0" }}>
          <Text style={{ fontSize: "24px", fontWeight: 800 }}>
            {t('successPurchase')}
          </Text>
        </Row>
        <Row style={{ justifyContent: "center", margin: "20px 0" }}>
          <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: "8rem"}} />
        </Row>
      </Modal>
      {/* // * End Success Buy Promotion Modal */}

      {/* // * Diamond Modal */}
      <Modal 
        centered={true} 
        open={isDiamondModalOpen} 
        maskClosable={true}
        footer={[
          <Row style={{ justifyContent: "center" }}>
            <Button block className="button-confirm" onClick={() => handleBuyCoinModal()}>
              <Text style={{fontSize: "18px", fontWeight: 700, color: "#F77725" }}>
                {t('buy')}
              </Text>
            </Button>
            {/* <Button block className="button-confirm" onClick={() => handleBuyCoinModal()} disabled>
              <Text style={{fontSize: "18px", fontWeight: 700 }}>
                {t('notAvailable')}
              </Text>
            </Button> */}
          </Row>
        ]}
        onCancel={closeCoinModal}
      >
        <Row style={{ justifyContent: "center", marginBottom: "20px" }}>
          <Text style={{fontSize: "32px", fontWeight: 700 }}>{ diamondValue }</Text>
        </Row>
        <Row style={{ justifyContent: "center", marginBottom: "20px" }}>
          <Image width="150px" src={diamondIcon} preview={false} />
        </Row>
        <Row style={{ width: "100%",  marginBottom: "20px" }}>
          <Card 
            style={{ 
              width: "100%", 
              borderRadius: "100px",
            }}
            bodyStyle={{
              display:"flex",
              justifyContent: "space-between", 
              padding: "10px 20px",
              alignItems: "center",

            }}
          >
            <Image src={minusIcon} preview={false} onClick={handleDecreaseAmount} />
            <div style={{ flex: 1, textAlign: 'center' }}>
              <Text style={{ fontSize: "18px", fontWeight: 700, color: "#F77725" }}>
                {topupQty}
              </Text>
            </div>
            <Image src={plusIcon} preview={false} onClick={handleIncreaseAmount} />
          </Card>  
        </Row>
      </Modal>
      {/* // * End Diamond Modal */}   

      {/* // * Success Buy Diamond Modal */}
      <Modal 
        centered={true} 
        open={isBuyDiamondModalOpen} 
        maskClosable={true}
        footer={[
          <Row style={{ justifyContent: "center" }}>
            <Button block className="button-confirm" onClick={closeBuyCoinModal}>
              <Text style={{fontSize: "18px", fontWeight: 700, color: "#F77725" }}>
                {t('done')}
              </Text>
            </Button>
          </Row>
        ]}
        onCancel={closeBuyCoinModal}
      >
        <Row style={{ justifyContent: "center", margin: "30px 0" }}>
          <Text style={{ fontSize: "24px", fontWeight: 800 }}>
            {t('successPurchase')}
          </Text>
        </Row>
        <Row style={{ justifyContent: "center", margin: "50px 0 20px 0" }}>
          <Image width="150px" src={diamondIcon} preview={false} />
        </Row>
        <Row style={{ justifyContent: "center", marginBottom: "30px" }}>
          <Text style={{ fontSize: "24px", fontWeight: 800 }}>
            {topupQty}x {diamondValue} {t('hopeCoin')}
          </Text>
        </Row>
      </Modal>
      {/* // * End Success Buy Diamond Modal */}
    </div>
  );
};

export default TopUp;
