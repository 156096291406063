import { LoginModel } from "../type/loginType";
import { apiRequest } from "../../../utils/API";

export const login = async (data: LoginModel) => {
  const phoneNumber = "+60" + data.phone;
  return await apiRequest<LoginModel>("POST", "/auth/player-login", {
    phone: phoneNumber,
    password: data.password,
  });
};
