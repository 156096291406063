import axios, { AxiosResponse, Method } from "axios";
import {
  getAccessToken,
  getToken,
  removeAuthSession,
} from "../utils/AuthService";

const apiHost = process.env.REACT_APP_API_HOST;

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    const tokens = getAccessToken();

    if (tokens) {
      config.headers.Authorization = `Bearer ${tokens}`;
    }
    return config;
  },

  (error) => Promise.reject(error)
);

const refreshToken = async () => {
  const tokens = getToken();

  try {
    const resp = await axiosInstance.post("v1/auth/refresh-tokens", {
      refreshToken: tokens?.refresh.token,
    });
    return resp.data;
  } catch (e) {
    console.log("Error", e);
    removeAuthSession();
    window.location.href = "/";
  }
};

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const tokens = getToken();
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      console.log("tokens", tokens);

      if (tokens !== null) {
        const newTokens = await refreshToken();

        // eslint-disable-next-line no-extra-boolean-cast
        if (!!newTokens) {
          axiosInstance.defaults.headers.common.Authorization = `Bearer ${newTokens.access.token}`;
        }

        return axiosInstance(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

async function apiRequest<T>(method: Method, url: string, data: any = {}) {
  const token = getAccessToken();
  let params;
  if (data.params) {
    params = data.params.params;
    console.log("params:", params);
  }
  try {
    const response: AxiosResponse = await axiosInstance({
      method,
      headers: { Authorization: token ? `Bearer ${token}` : "" },
      url: apiHost + url,
      params,
      data,
    });
    return { status: "success", data: response.data };
  } catch (error: any) {
    console.log("in api error:", error);
    throw new Error(
      error.response?.data?.message || "API request failed: Connection Error",
    );
  }
}

export { apiRequest };
