import { useTranslation } from "react-i18next";

import moment from 'moment';
import { useQuery } from "@tanstack/react-query";

import { Typography, Col, Row, Space, List, Button, Tag, message, Divider } from "antd";
import { LeftOutlined, CopyOutlined } from '@ant-design/icons';

import { getRedeemHistory } from "../api/ReloadPinAPI";
import { RedeemHistoryModel } from "../type/redeemType";

const RedeemHistory = () => {
  const { t } = useTranslation();
  const { Text } = Typography;
  
  function copyCode(value: string) {
    navigator.clipboard.writeText(value);
    message.success(t('copySuccess'));
  };

  const { 
    data: redeemHistory, 
    isLoading, 
    isError,
  } = useQuery<RedeemHistoryModel, Error>({
    queryKey: ["redeemHistory"],
    queryFn: () => getRedeemHistory(),
    retry: false,
    onError: (error) => {
      message.error(error.message);
    },
  });

  return (
    <div>
      <Space direction="vertical" size="middle" style={{ width:"100%" }}>
        <Row>
          <Col span={24}>
            <Button style={{ padding: 0 }} type="text" onClick={()=> history.back()}>
              <Text style={{ fontSize: "16px", fontWeight: 600, color: "#FFFFFF" }}>
                <LeftOutlined /> {t('redeemHistory')}
              </Text>
            </Button>
          </Col>
        </Row>
        <Row align="middle">
          <List
            style={{ width:"100%" }}
            loading={isLoading}
            dataSource={redeemHistory?.results}
            renderItem={(item) => (
              <List.Item>
                <Row style={{ width:"100%" }}>
                  <Col span={12}>
                    <Row>
                      <Text style={{ fontSize: "16px", fontWeight: 600, color: "#FFFFFF" }}>
                        {item.code} <CopyOutlined onClick={() => copyCode(item.code) } />
                      </Text>
                    </Row>
                    <Row>
                      <Text style={{ fontSize: "14px", color: "#8C8C8C" }}>
                        {t('transactionId')}: {item.transactionId ?? "Null"}
                      </Text>
                    </Row>
                    <Row>
                      <Text style={{ fontSize: "12px", color: "#8C8C8C" }}>
                        {moment(item.createdAt).format('DD/MM/YYYY, h:mma')}
                      </Text>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row style={{ justifyContent: "end" }}>
                      <Text style={{ fontSize: "16px", fontWeight: 700, color: "#FFFFFF" }}>
                          -{item.cost} {t('heeCoin')}
                      </Text>
                    </Row>
                    <Row style={{ justifyContent: "end" }}>
                      { item.status === "Redeemed" ? (
                        <Tag color="lime" style={{ margin: "0", borderRadius: "2px" }}>
                          {item.status}
                        </Tag>
                      ) : (
                        ''
                      )}
                      { item.status === "Suspend" ? (
                        <Tag color="red" style={{ margin: "0", borderRadius: "2px" }}>
                          {item.status}
                        </Tag>
                      ) : (
                        ''
                      )}
                    </Row>
                  </Col>
                  <Divider style={{ backgroundColor: "#262626", margin: "20px 0 0 0" }} />
                </Row>
              </List.Item>
            )}
          />
        </Row>
      </Space>
    </div>
  );
};

export default RedeemHistory;
