import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";

import moment from "moment";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { Button, Typography, Col, Row, Space, Card, Image, Radio, Modal, List, message, Spin, Form, Input } from "antd";
import type { RadioChangeEvent } from 'antd';
import { DollarOutlined, RightOutlined } from '@ant-design/icons';

import { getPlayerData, updateFirstTimeReward, validatePlayer } from "../../api/GeneralAPI";
import { getRedeemPlatform, getPlatformAmount, redeemPin } from "./api/ReloadPinAPI"
import { getHeeCoinRatio, getUser, setUser } from "../../utils/AuthService";
import { PlayerModel, ValidatePlayerModel, ValidatePlayerSchema } from "../../types/GenerateTypes";
import { AmountModel, RedeemModel } from "./type/redeemType";

import heecoinIcon from "../../assets/svg/heecoin.svg";
// import heecoinBorder from "../../assets/svg/heecoin-boarder.svg";
import diamondBg from "../../assets/svg/background-redeem.svg";
import dollarEnable from "../../assets/svg/dollars-enable.svg";

import './css/redeem.css';

const Redeem = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient()
  const { t } = useTranslation();
  const { Text } = Typography;
  const [modal, successModal] = Modal.useModal();

  const playerData = getUser();
  const heecoinRatio = getHeeCoinRatio();

  const [isLaptopMode, setIsLaptopMode] = useState(window.innerWidth > 768);
  const [platformId, setPlatformId] = useState("");
  const [isNextStep, setIsNextStep] = useState(false);
  const [redeemAmountList, setRedeemAmountList] = useState<AmountModel[]>([]);
  const [platformAmountValue, setPlatformAmountValue] = useState("");
  const [heecoinAmountValue, setHeecoinAmountValue] = useState("");
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isSuccessRedeemModalOpen, setIsSuccessRedeemModalOpen] = useState(false);
  const [isFirstBonusModalOpen, setIsFirstBonusModalOpen] = useState(false);
  const [isValidatePlayerModalOpen, setValidatePlayerModalOpen] = useState(false);
  const [validateFor, setValidateFor] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [reloadPinValue, setReloadPinValue] = useState("");

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ValidatePlayerModel>({
    resolver: zodResolver(ValidatePlayerSchema),
  });

  // * Platform
  const onPlatformChange = (e: RadioChangeEvent) => {
    const platformId = e.target.value;
    setPlatformAmountValue("");
    setPlatformId(platformId)
  };
  
  const handleNextStep = ()  => {
    if (platformId && !isNextStep) {
      redeemAmount.mutate(platformId);
    }
    setIsNextStep(!isNextStep);
  };
  
  const onPlatformAmountChange = (e: RadioChangeEvent) => {
    const amount = e.target.value;
    setPlatformAmountValue(amount)
  };
  // * End Platform

  // * Confirm Redeem
  const showConfirmModal = () => {
    const heecoinRatio = getHeeCoinRatio();
    setHeecoinAmountValue((Number(platformAmountValue) * Number(heecoinRatio))?.toString());
    setIsConfirmModalOpen(true);
  };

  const handleCancelConfirmRedeem = () => {
    setIsConfirmModalOpen(false);
  };

  const handleValidateForRedeem = () => {
    setIsConfirmModalOpen(false);
    setValidatePlayerModalOpen(true);
    setValidateFor("redeem");
  };

  const onSubmitConfirmRedeem = async (data: ValidatePlayerModel) => {
    try {
      await validatePlayer(data);
      try {
        const pin = await redeemPin(platformId, Number(platformAmountValue))
        setReloadPinValue(pin.code);
        setIsConfirmModalOpen(false);
        setIsSuccessRedeemModalOpen(true);
        setPlatformAmountValue("");
        setPlatformId("");
        setIsNextStep(!isNextStep);
        setValidatePlayerModalOpen(false);
        queryClient.invalidateQueries({ queryKey: ['playerData'] })
      } catch (error) {
        const err = error as Error;
        message.error(err.message);
      }
    } catch (error) {
      setIsLoading(false);
      const err = error as Error;
      message.error(err.message);
    }
  };
  // * End Confirm Redeem

  // * Success Redeem
  const handleCancelSuccessRedeem = () => {
    setIsSuccessRedeemModalOpen(false);
  };
  
  function handleSuccessRedeem() {
    navigator.clipboard.writeText(reloadPinValue);
    message.success(t('copySuccess'));
  };
  // * End Success Redeem

  // * First Bonus
  const showBonusModal = (bonusAvailable: boolean) => {
    setIsFirstBonusModalOpen(bonusAvailable);
  };

  const handleCancelBonusModal = () => {
    setIsFirstBonusModalOpen(false);
  };

  const handleValidateForBonus = () => {
    setIsFirstBonusModalOpen(false);
    setValidatePlayerModalOpen(true);
    setValidateFor("bonus");
  };

  const onSubmitConfirmBonus = async (data: ValidatePlayerModel) => {
    try {
      await validatePlayer(data);
      try {
        setIsLoading(true);
        await updateFirstTimeReward();
        setTimeout(() => {
          setIsLoading(false);
          openSuccessReward();
        }, 1000);
        playerInfo.mutate();
      } catch (error) {
        setIsLoading(false);
        const err = error as Error;
        message.error(err.message);
      }
    } catch (error) {
      setIsLoading(false);
      const err = error as Error;
      message.error(err.message);
    }
  };

  const openSuccessReward = () => {
    let secondsToGo = 3;

    const instance = modal.success({
      className: "success-redeem-modal",
      content: t('successClaim'),
      footer: false,
      centered: true,
    });

    setTimeout(() => {
      instance.destroy();
    }, secondsToGo * 1000);
  };
  // * End First Bonus

  // * Validate Player
  const handleCancelValidateModal = () => {
    setValidatePlayerModalOpen(false);
    setValidateFor("");
  };
  // * End Validate Player

  const { 
    data: platformList, 
    isLoading: platformLoading, 
    isError: platformError,
  } = useQuery<RedeemModel[], Error>({
    queryKey: ["platformList"],
    queryFn: () => getRedeemPlatform(),
    onError: (error) => {
      message.error(error.message);
    },
    retry: false,
  });

  const redeemAmount = useMutation<AmountModel[], Error, String>({
    mutationFn: async (platformId) => await getPlatformAmount(platformId as string),
    onSuccess: (data) => {
      const amountData = data.map((data) => ({
        id: data?.toString(),
        price: Number(data),
        isEnable: true
      }))
      setRedeemAmountList(amountData)
    },
    onError: (error) => {
      message.error(error.message);
    },
  })

  const playerInfo = useMutation<PlayerModel, Error>({
    mutationFn: async () => await getPlayerData(),
    onSuccess: (data) => {
      setUser(data);
      setIsFirstBonusModalOpen(data.firstBonusAvail);
      setValidatePlayerModalOpen(false);
    },
    onError: (error) => {
      message.error(error.message);
    },
  })
  
  useEffect(() => {
    const handleResize = () => {
      setIsLaptopMode(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  return (
    <div>
      <Spin spinning={isLoading} fullscreen />
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Space size="small" direction="vertical" style={{ width: "100%" }}>
          <Row>
            <Space size="middle">
              <DollarOutlined />
              <Text style={{ fontSize:"16px" }}>
                {t('redeem')}
              </Text>
            </Space>
          </Row>
          <Row>
            <Text style={{ fontSize:"12px" }}>
              {t('redeemInfo')}
            </Text>
          </Row>
          <Row>
            <Card 
              style={{ 
                width: "100%",
                background: "var(--orange-gradient, linear-gradient(114deg, #F58C31 -9.08%, #F76C1C -9.07%, #F68C34 28.36%, #FAB44C 70.57%, #F9D152 105.05%))",
              }} 
              bodyStyle={{  
                backgroundImage: `url(${diamondBg})`, 
                backgroundSize: "cover", 
                backgroundPosition: "center",
                fontSize:"16px", 
                textAlign:"center", 
                padding: "10px 0", 
              }} 
              type="inner"
            >
              <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                <Image src={heecoinIcon} width={isLaptopMode ? "15%" : "20%"} preview={false} />
                <div>
                  <Text style={{ fontSize:"24px", color:"black", fontWeight:700 }}>
                    { playerData?.heeCoin ? playerData?.heeCoin?.toString() : "0" } {t('heeCoin')}
                  </Text>
                </div>
                <div>
                  <Text style={{ fontSize:"12px", color:"black" }}>
                    {t('lastUpdated')}: { playerData?.heeCoinLastUpdated ? moment(playerData?.heeCoinLastUpdated).format("DD/MM/YYYY") : "-" }
                  </Text>
                </div>
              </Space>
            </Card>
          </Row>
          <Row>
            <Text style={{ fontSize:"12px" }}>
              { heecoinRatio ?? "Null" } {t('heeCoin')} = RM 1.00
            </Text>
          </Row>
        </Space>
        {/* { playerData && playerData!.firstBonusAvail === true ?
          <Row style={{ justifyContent: 'left' }}>
            <Button type="primary" size="large" onClick={() => showBonusModal(playerData!.firstBonusAvail)} style={{ 
              height: `10px !important`,
              background: `var(--orange-gradient, linear-gradient(114deg, #F58C31 -9.08%, #F76C1C -9.07%, #F68C34 28.36%, #FAB44C 70.57%, #F9D152 105.05%))`,
              border: `1px solid #f0f0f0`,
            }}>
              <Text style={{ fontSize: "12px", fontWeight: 700, color: "#FFFFFF" }}>
                {t('promoReward')}
              </Text>
            </Button>
          </Row> 
          : null
        } */}
        { isNextStep ?
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <Row>
              <Text  style={{ fontSize:"16px" }}>
                {t('chooseRedeemAmount')}:
              </Text>
            </Row>
            <Row style={ redeemAmountList.length !== 0 ? { width: "100%", display: "flex" } : { width: "100%", display: "flex", justifyContent: "center" }}>
              <List
                grid={{ 
                  gutter: 10,
                  xs: 3,
                  sm: 3,
                }}
                loading={redeemAmount.isLoading}
                dataSource={redeemAmountList}
                renderItem={(item) => (
                  <List.Item>
                    <Radio.Group style={{ marginRight: "70px" }} size="large" buttonStyle="solid" onChange={onPlatformAmountChange} value={platformAmountValue}>
                      <Radio.Button 
                        style={ isLaptopMode ? 
                          { height: "10rem", width: "10rem", padding: "0 15px", display: "flex", alignItems: "center", justifyContent: "center" } : 
                          { height: "7rem", width: "7rem", padding: "0 15px", display: "flex", alignItems: "center", justifyContent: "center" }
                        }
                        value={item.id}
                        className="platform-button"
                      >
                        <Row style={{ justifyContent:"center" }}>
                          <Image
                            width={"80%"}
                            src={dollarEnable}
                            preview={false}
                          />
                        </Row>
                        <Row style={{ justifyContent:"center" }}>
                          RM {item.price?.toString()}
                        </Row>
                      </Radio.Button> 
                    </Radio.Group>
                  </List.Item>
                )}
              />
            </Row>
          </Space>
        :
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <Row>
              <Text  style={{ fontSize:"16px" }}>
                {t('chooseRedeemPlatform')}:
              </Text>
            </Row>
            <Row style={{ width: "100%", display: "flex" }}>
              <List
                grid={{ 
                  gutter: 10, 
                  xs: 3,
                  sm: 3,
                }}
                dataSource={platformList}
                loading={platformLoading}
                renderItem={(item) => (
                  <List.Item>
                      <Radio.Group size="large" buttonStyle="solid" onChange={onPlatformChange} value={platformId}>
                        <Radio.Button 
                          style={ isLaptopMode ? 
                            { height: "13rem", width: "10rem", padding: "0 15px", display: "flex", alignItems: "center", justifyContent: "center" } : 
                            { height: "10rem", width: "7rem", padding: "0 15px", display: "flex", alignItems: "center", justifyContent: "center" }
                          }
                          value={item.id}
                          className="platform-button"
                        >
                          <Space direction="vertical" size="small" style={{ width: "100%" }}>
                            <Row style={{ justifyContent: "center" }}>
                              <Image
                                width={"80%"}
                                src={item.logoUrl}
                                preview={false}
                              />
                            </Row>
                            <Row style={{ justifyContent: "center", lineHeight: "20px", overflow: "hidden", textOverflow: "ellipsis", height: "40px" }}>
                              {item.name}
                            </Row>
                          </Space>
                        </Radio.Button> 
                      </Radio.Group>
                  </List.Item>
                )}
                />
            </Row>
          </Space>
        }
        { isNextStep ?
          <Row style={{ display:"flex", justifyContent: "space-between" }}>
            <Col style={{ width: "20%" }}>
              <Button type="primary" size="large" className="button-back" onClick={handleNextStep}>
                <Text style={{fontSize: "18px", fontWeight: 700, color: "#FFFFFF"}}>
                  {t('back')}
                </Text>
              </Button>
            </Col>
            <Col style={{ width: "75%" }}>
              <Button type="primary" size="large" className="button-redeem"  onClick={showConfirmModal} disabled={platformAmountValue === ""}>
                <Text style={{fontSize: "18px", fontWeight: 700, color: "#FFFFFF"}}>
                  {t('redeem')}
                </Text>
              </Button>
            </Col>
          </Row>
        :
          <Row style={{ justifyContent: 'center' }}>
            <Button type="primary" size="large" className="default-button"  onClick={handleNextStep} disabled={platformId === ""}>
              <Text style={{fontSize: "18px", fontWeight: 700, color: "#FFFFFF"}}>
                {t('next')}
              </Text>
            </Button>
          </Row>
        }
        
        <Row style={{ justifyContent: 'center', margin: '10px 0' }}>
          <Button type="text" onClick={()=> navigate(`/redeem/history`)}>
            <Text style={{fontSize: "16px", fontWeight: 600, color: "#FFFFFF"}}>
              {t('viewRedeemHistory')} <RightOutlined  />
            </Text>
          </Button>
        </Row>
      </Space>

      {/* // * Confirm Redeem Modal */}
      <Modal 
        centered={true} 
        open={isConfirmModalOpen} 
        maskClosable={true}
        footer={[
          <Row style={{ justifyContent: "center" }}>
            <Button block className="button-confirm" onClick={handleValidateForRedeem}>
              <Text style={{fontSize: "18px", fontWeight: 700, color: "#F77725" }}>
                {t('confirm')}
              </Text>
            </Button>
          </Row>
        ]}
        onCancel={handleCancelConfirmRedeem}
      >
        <Row style={{ justifyContent: "center", marginBottom: "20px" }}>
          <Text style={{fontSize: "32px", fontWeight: 700 }}>{ heecoinAmountValue } {t('heeCoin')}</Text>
        </Row>
        <Row style={{ justifyContent: "center", marginBottom: "20px" }}>
          <Image src={heecoinIcon} width="20%" preview={false} />
        </Row>
      </Modal>
      <Modal 
        centered={true} 
        open={isSuccessRedeemModalOpen} 
        maskClosable={true}
        footer={[
          <Row style={{ justifyContent: "center" }}>
            <Button block className="button-success-redeem" onClick={() =>  handleSuccessRedeem()}>
              <Row style={{ justifyContent: "center" }}>
                <Text style={{ fontSize: "16px", fontWeight: 800, color: "#434343" }}>
                  { reloadPinValue }
                </Text>
              </Row>
              <Row style={{ justifyContent: "center" }}>
                <Text style={{ fontSize: "14px", fontWeight: 700, color: "#BFBFBF" }}>
                  {t('tabCopy')}
                </Text>
              </Row>
            </Button>
          </Row>
        ]}
        onCancel={handleCancelSuccessRedeem}
      >
        <Row style={{ justifyContent: "center", marginBottom: "20px" }}>
          <Text style={{ fontSize: "24px", fontWeight: 800 }}>
            {t('successRedeem')}
          </Text>
        </Row>
        <Row style={{ justifyContent: "center", marginBottom: "20px" }}>
          <Image src={heecoinIcon} width="20%" preview={false} />
        </Row>
      </Modal>

      {/* // * First Bonus Modal */}
      <Modal 
        centered={true} 
        open={isFirstBonusModalOpen} 
        maskClosable={true}
        footer={[
          <Row style={{ justifyContent: "center" }}>
            <Button block className="button-confirm" onClick={handleValidateForBonus}>
              <Text style={{fontSize: "18px", fontWeight: 700, color: "#F77725" }}>
                {t('validate')}
              </Text>
            </Button>
          </Row>
        ]}
        onCancel={handleCancelBonusModal}
      >
        <Row style={{ justifyContent: "center", marginBottom: "20px" }}>
            <Text style={{fontSize: "25px", fontWeight: 700 }}>{t('rewardList')}</Text>
        </Row>
        <ul style={{ color: "white" }}>
          <li>
            <Row style={{ justifyContent: "left" }}>
              <Text style={{fontSize: "18px" }}>{t('rewardList1')}</Text>
            </Row>
          </li>
          <li>
            <Row style={{ justifyContent: "left" }}>
              <Text style={{fontSize: "18px" }}>{t('rewardList2')}</Text>
            </Row>
          </li>
          <li>
            <Row style={{ justifyContent: "left" }}>
              <Text style={{fontSize: "18px" }}>{t('rewardList3')}</Text>
            </Row>
          </li>
          <li>
            <Row style={{ justifyContent: "left", marginBottom: "20px" }}>
              <Text style={{fontSize: "18px" }}>{t('rewardList4')}</Text>
            </Row>
          </li>
        </ul>
      </Modal>
      <Modal 
        centered={true} 
        open={isValidatePlayerModalOpen} 
        maskClosable={true}
        footer={[]}
        onCancel={handleCancelValidateModal}
      >
        <Form
          name="loginForm"
          layout="vertical"
          onFinish={handleSubmit(validateFor === "bonus" ? onSubmitConfirmBonus : onSubmitConfirmRedeem)}
          labelAlign="left"
          colon={false}
        >
          <Row style={{ marginBottom: "20px" }}>
            <Text style={{fontSize: "25px", fontWeight: 700 }}>{t('validateUser')}</Text>
          </Row>
          <Form.Item
            name="password"            
            label={<label style={{ fontSize:"16px", color:"white" }}>{t('password')}</label>}
            hasFeedback
            validateStatus={errors.password ? "error" : ""}
            help={errors.password?.type === "too_small" ? t('errorPassword') : errors.password?.type === "invalid_type" ? t('required') : null}
            className="custom-validate-color"
          >
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <Input.Password size="large" style={{padding: "15px", fontSize: "11px"}} placeholder={t('hintPassword')} {...field} />
              )}
            />
          </Form.Item>

          <Form.Item style={{ width: "100%", marginTop: 30  }}>
            <Row style={{ justifyContent: "center" }}>
              <Button
                type="primary"
                htmlType="submit"
                block
                className="button-confirm"
              >
                <Text style={{fontSize: "18px", fontWeight: 700, color: "#F77725" }}>
                  {t('claim')}
                </Text>
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
      {successModal}
    </div>
  );
};

export default Redeem;
