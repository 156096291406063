import { useTranslation } from "react-i18next";

import { Typography, Col, Row, Space, List, Card, Image } from "antd";

import { getLanguage, setLanguage } from "../../utils/AuthService";

import englishIcon from "../../assets/svg/english.svg";
import cnIcon from "../../assets/svg/china.svg";

const LanguageSetting = () => {
  const { i18n, t } = useTranslation();
  const { Text } = Typography;

  const selectedLanguage = getLanguage(); 

  const dataSetting = [
    {
      "title": "English",
      "value": "en",
      "image": englishIcon,
    },
    {
      "title": "Chinese",
      "value": "cn",
      "image": cnIcon,
    },
  ];

  const handleLanguageChange = (value: string) => {
    setLanguage(value);
    i18n.changeLanguage(value);
  };

  return (
    <div>
      <Space direction="vertical" size="large" style={{ width:"100%" }}>
        <Row style={{ justifyContent: "center", marginTop: "20px"}}>
          <Text style={{ fontSize: "20px", fontWeight: 700 }}>
            {t('language')}
          </Text>
        </Row>
        <List
          style={{ width: "100%", paddingInline: "50px" }}
          dataSource={dataSetting}
          renderItem={(item) => (
            <List.Item style={{ padding: "8px 0" }}> 
              { selectedLanguage === item.value 
                ?
                  <Card 
                    style={{ 
                      width: "100%",
                      background: "var(--orange-gradient, linear-gradient(114deg, #F58C31 -9.08%, #F76C1C -9.07%, #F68C34 28.36%, #FAB44C 70.57%, #F9D152 105.05%))",
                      border: "1px solid #025E9E",
                    }} 
                    onClick={() => handleLanguageChange(item.value)}
                  >
                    <Row style={{ alignItems: "center" }}>
                      <Space size="large">
                        <Col>
                          <Image src={item.image} preview={false} />
                        </Col>
                        <Col>
                          <Text style={{ fontSize: "16px", fontWeight: 700, color: "#FFFFFF" }}>
                            {item.title}
                          </Text>
                        </Col>
                      </Space>
                    </Row>
                  </Card>
                :   
                  <Card 
                    style={{ 
                      width: "100%",
                    }} 
                    onClick={() => handleLanguageChange(item.value)}
                  >
                    <Row style={{ alignItems: "center" }}>
                      <Space size="large">
                        <Col>
                          <Image src={item.image} preview={false} />
                        </Col>
                        <Col>
                          <Text style={{ fontSize: "16px", fontWeight: 700, color: "#000000" }}>
                            {item.title}
                          </Text>
                        </Col>
                      </Space>
                    </Row>
                  </Card>
              }
            </List.Item>
          )}
        />
      </Space>
    </div>
  );
};

export default LanguageSetting;
