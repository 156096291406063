import { Route, Routes } from "react-router-dom";

import Redeem from "../pages/redeem/Redeem";
import RedeemHistory from "../pages/redeem/view/RedeemHistory";
import TopUp from "../pages/topUp/TopUp";
import TopUpHistory from "../pages/topUp/view/TopUpHistory";
import Profile from "../pages/profile/Profile";
import ProfileAccount from "../pages/profile/view/ProfileAccount";
import LanguageSetting from "../pages/languageSetting/languageSetting";
import NotFoundPage from "../pages/notFoundPage/notFoundPage";

export default function RouteList() {
  const handleAdminRoutes = () => (
    <>
      <Route path="/" element={<Redeem />} />
      <Route path="/redeem" element={<Redeem />} />
      <Route path="/redeem/history" element={<RedeemHistory />} />
      <Route path="/top-up" element={<TopUp />} />
      <Route path="/top-up/history" element={<TopUpHistory />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/profile/account" element={<ProfileAccount />} />
      <Route path="/language-setting" element={<LanguageSetting />} />
    </>
  );

  // TODO modify when API implementation is done
  const handleRolesRoutes = (roles: string) => {
    switch (roles) {
      case "operator":
        return NotFoundPage();
      case "admin":
        return handleAdminRoutes();
      default:
        return NotFoundPage();
    }
  };

  return <Routes>{handleRolesRoutes("admin")}</Routes>;
}
