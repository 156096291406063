import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Login from "./pages/login/Login";
import ValidateLogin from "./pages/validateLogin/ValidateLogin";
import Redeem from "./pages/redeem/Redeem";
import RedeemHistory from "./pages/redeem/view/RedeemHistory";
import TopUp from "./pages/topUp/TopUp";
import TopUpHistory from "./pages/topUp/view/TopUpHistory";
import Profile from "./pages/profile/Profile";
import ProfileAccount from "./pages/profile/view/ProfileAccount";
import LanguageSetting from "./pages/languageSetting/languageSetting";
// import Dashboard from "./pages/dashboard/DashboardPage";
// import ForgotPassword from "./pages/forgotPassword/ForgotPassword";
// import PermissionList from "./pages/permission/PermissionList";
// import Permission from "./pages/permission/view/Permission";
// import Users from "./pages/products/Products";
// import ProductAddNew from "./pages/products/view/ProductsAddNew";
// import ProductDetails from "./pages/products/view/ProductsDetails";
// import RegisterPage from "./pages/register/Register";
// import RolesList from "./pages/roles/RolesList";
// import Roles from "./pages/roles/view/Roles";
// import UserManagement from "./pages/userManagement/UserManagement";
// import UserAdd from "./pages/userManagement/view/User";
import { PrivateRoute, PublicRoute } from "./utils/RouteHandlers";

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename="/">
        <Routes>
          <Route element={<PublicRoute />}>
            <Route path="/validate-login/:accessToken/:userId" Component={ValidateLogin} />
            <Route path="/login" Component={Login} />
            <Route path="/" Component={Login} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/redeem" element={<Redeem />} />
            <Route path="/redeem/history" element={<RedeemHistory />} />
            <Route path="/top-up" element={<TopUp />} />
            <Route path="/top-up/history" element={<TopUpHistory />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/profile/account" element={<ProfileAccount />} />
            <Route path="/language-setting" element={<LanguageSetting />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;
