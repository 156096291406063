import { apiRequest } from "../../../utils/API";
import { TopUpModel, TopupHistoryModel } from "../type/topupType";

export const getTopUpList = async () => {
  const response = await apiRequest<TopUpModel>("GET", "/items/hopecoin/senangpay");

  return response.data;
};

export const getPaymentUrl = async (productId: string, quantity: number | null) => {
    return await apiRequest("POST", "/items/hopecoin/senangPay/buy", quantity !== 0 ? { productId, quantity } : { productId });
};

export const getTopupHistory = async (playerId: string | undefined) => {
  const response = await apiRequest<TopupHistoryModel>("GET", `/transactions?playerId=${playerId}&category=topup`);

  return response.data;
};
