import { Navigate, Outlet, useParams } from "react-router-dom";

import i18n from "../i18n";

import MainLayout from "../components/layout/MainLayout";
import { getAccessToken, setAccessToken, setLanguage, setPathName } from "./AuthService";

type TRouteHandler = {
  isAuthenticated: boolean;
};

export const PrivateRoute = () => {
  const { accessToken: token } = useParams<{ accessToken: string }>();
  const { userId: userID } = useParams<{ userId: string }>();
  if (token && userID){
    setAccessToken(token, userID);
    setPathName("redeem");
    setLanguage(i18n.language);
  }
  const isAuthenticated = getAccessToken(); // to update auth logic
  return isAuthenticated ? (
    <MainLayout>
      {" "}
      <Outlet />
    </MainLayout>
  ) : (
    <Navigate to="/login" />
    );
  };
  
export const PublicRoute = () => {
  const { accessToken: token } = useParams<{ accessToken: string }>();
  const { userId: userID } = useParams<{ userId: string }>();
  if (token && userID){
    setAccessToken(token, userID);
    setPathName("redeem");
    setLanguage(i18n.language);
  }
  const isAuthenticated = getAccessToken(); // to update auth logic
  return !isAuthenticated ? <Outlet /> : <Navigate to="/redeem" />;
};
