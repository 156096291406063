import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useTranslation } from "react-i18next";

import {
  Space,
  Button,
  Col,
  Image,
} from "antd";

import { setPathName, getPathName, getLanguage } from "../../utils/AuthService";

import redeemActive from "../../assets/svg/redeem-active.svg";
import redeemInactive from "../../assets/svg/redeem-inactive.svg";
import topupActive from "../../assets/svg/topup-active.svg";
import topupInactive from "../../assets/svg/topup-inactive.svg";
import profileActive from "../../assets/svg/profile-active.svg";
import profileInactive from "../../assets/svg/profile-inactive.svg";
import redeemActiveCn from "../../assets/svg/redeem-active-cn.svg";
import redeemInactiveCn from "../../assets/svg/redeem-inactive-cn.svg";
import topupActiveCn from "../../assets/svg/topup-active-cn.svg";
import topupInactiveCn from "../../assets/svg/topup-inactive-cn.svg";
import profileActiveCn from "../../assets/svg/profile-active-cn.svg";
import profileInactiveCn from "../../assets/svg/profile-inactive-cn.svg";

const FooterLayout = () => {
  const navigate = useNavigate();

  const selectedLanguage = getLanguage(); 
  const currentRoute = getPathName(); 
  
  const [isLaptopMode, setIsLaptopMode] = useState(window.innerWidth > 768);
  
  function onSelectPage(value: string) {
    setPathName(value);
    switch (value) {
      case "redeem":
        navigate(`/redeem`);
        break;
      case "top-up":
        navigate(`/top-up`);
        break;
      case "profile":
        navigate(`/profile`);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsLaptopMode(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <Space size="small" style={{ width: "100%", justifyContent: "space-around" }}>
        <Col>
          <Button type="text" style={{ height: "auto", padding: 0 }} onClick={() => onSelectPage("redeem")}>
            { currentRoute === "redeem" ? (
              selectedLanguage === "cn" ? <Image src={redeemActiveCn} width={isLaptopMode ? 150 : 100} preview={false} /> : <Image src={redeemActive} width={isLaptopMode ? 150 : 100} preview={false} />
            ) : (
              selectedLanguage === "cn" ? <Image src={redeemInactiveCn} width={isLaptopMode ? 150 : 100} preview={false} /> : <Image src={redeemInactive} width={isLaptopMode ? 150 : 100} preview={false} />
            )}
          </Button>
        </Col>
        <Col>
          <Button type="text" style={{ height: "auto", padding: 0 }} onClick={() => onSelectPage("top-up")}>
            { currentRoute === "top-up" ? (
              selectedLanguage === "cn" ? <Image src={topupActiveCn} width={isLaptopMode ? 150 : 100} preview={false} /> : <Image src={topupActive} width={isLaptopMode ? 150 : 100} preview={false} />
              ) : (
              selectedLanguage === "cn" ? <Image src={topupInactiveCn} width={isLaptopMode ? 150 : 100} preview={false} /> : <Image src={topupInactive} width={isLaptopMode ? 150 : 100} preview={false} />
            )}
          </Button>
        </Col>
        <Col>
          <Button type="text" style={{ height: "auto", padding: 0 }} onClick={() => onSelectPage("profile")}>
            { currentRoute === "profile" ? (
              selectedLanguage === "cn" ? <Image src={profileActiveCn} width={isLaptopMode ? 150 : 100} preview={false} /> : <Image src={profileActive} width={isLaptopMode ? 150 : 100} preview={false} />
              ) : (
              selectedLanguage === "cn" ? <Image src={profileInactiveCn} width={isLaptopMode ? 150 : 100} preview={false} /> : <Image src={profileInactive} width={isLaptopMode ? 150 : 100} preview={false} />
            )}
          </Button>
        </Col>
      </Space>
    </div>
  );
};

export default FooterLayout;
