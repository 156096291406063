import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Spin } from 'antd';

import { setAccessToken, setPathName, setLanguage } from "../../utils/AuthService";

const ValidateLoginPage = () => {
  const { i18n} = useTranslation();
  const navigate = useNavigate();

  const { accessToken: token } = useParams<{ accessToken: string }>();
  const { userId: userID } = useParams<{ userId: string }>();

  useEffect(() => {
    if( token && userID ) {
      setAccessToken(token, userID);
      setPathName("redeem");
      setLanguage(i18n.language);
      navigate(0);
    }
  }, []);

  return (
    <Spin spinning={true} fullscreen={true} />
  );
};

export default ValidateLoginPage;
