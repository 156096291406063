import { apiRequest } from "../../../utils/API";
import { AmountModel, RedeemHistoryModel, RedeemModel } from "../type/redeemType";

export const getPlayer = async () => {
  return await apiRequest("GET", "/players/me");
};

export const getHeecoinConfig = async () => {
  return await apiRequest("GET", '/configs');
}

export const getRedeemPlatform = async () => {
  const response =  await apiRequest<RedeemModel[]>("GET", "/reloadPINPlatform");

  return response.data;
};

export const getPlatformAmount = async (platformId: string) => {
  const response = await apiRequest<AmountModel[]>("GET", `/reloadPIN/getPINs/${platformId}`);

  return response.data;
}

export const redeemPin = async (platformId: string, amount: number) => {
  const response =  await apiRequest("GET", `/reloadPIN/getPIN?platform=${platformId}&amount=${amount}`);

  return response.data;
}

export const getRedeemHistory = async () => {
  const response = await apiRequest<RedeemHistoryModel>("GET", "/reloadPIN/myRedeemed");

  return response.data;
}
