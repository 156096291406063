import { apiRequest } from "../utils/API";
import { setHeeCoinRatio } from "../utils/AuthService";
import { PlayerModel, ValidatePlayerModel } from "../types/GenerateTypes";

export const getPlayerData = async () => {
  const response = await apiRequest<PlayerModel>("GET", "/players/me");

  return response.data;
};

export const getHeeCoinConfig = async () => {
  const response = await apiRequest("GET", '/configs');
  setHeeCoinRatio(response.data.results[1].value);

  return response.data;
}

export const updateFirstTimeReward = async () => {
  return await apiRequest("POST", '/items/firstTimeReward');
}

export const validatePlayer = async (data: ValidatePlayerModel) => {
  const response =  await apiRequest("POST", '/auth/player-validate', {
    password: data.password,
  });

  return response.data;
}