import React from "react";
import { ConfigProvider, Empty } from "antd";
import en_US from "antd/locale/en_US";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import { t } from "i18next";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const primaryButtonColor = "#FF0000";
root.render(
  <ConfigProvider
    locale={en_US}
    renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('noData')} />}
    theme={{
      token: {
        colorPrimary: "#FFFFFF",
      },
      components: {
        // Button theme
        Button: {
          colorPrimary: "#FFFFFF",
          borderRadius: 27,
          colorPrimaryHover: "#FFFFFF",
          colorTextLightSolid: "black",
        },

        Layout: {
          //header theme
          headerBg: "white",
          colorText: "#6C6C6C",
        },
        Menu: {
          // Sidebar them
          colorText: "#A6A6A6",
          colorLinkActive: "#0C377C",
          itemBg: "#023047", // menu bg color
          itemSelectedColor: "white", // menu item selected text color
          itemBorderRadius: 0,
          colorPrimaryBorder: "2px solid green",
          itemHoverBg: "none",
          itemHoverColor: "white",
        },
        Breadcrumb: {
          lastItemColor: "#0C377C",
          linkColor: "#0C377C",
          separatorColor: "#0C377C",
        },
        Input: {
          colorBorder: "#A6A6A6",
          borderRadius: 4,
        },
        Select: {
          colorBorder: "#A6A6A6",
          borderRadius: 4,
        },
        Typography: {
          colorText: "#FFFFFF",
        },
        Radio: {
          colorTextLightSolid: "black",
        },
      },
    }}
  >
    <App />
  </ConfigProvider>,
);

reportWebVitals();
