import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Typography, Col, Row, Space, List, Avatar, Divider, Card } from "antd";
import { RightOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { getHeeCoinRatio, getUser } from "../../utils/AuthService";

import heecoinIcon from "../../assets/svg/heecoin-background.svg";

const Profile = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { Text } = Typography;

  const playerData = getUser();
  const heecoinRatio = getHeeCoinRatio();

  const dataSetting = [
    {
      "title": t('account'),
      "redirect": `/profile/account`,
      "isOutSource": false,
    },
    {
      "title": t('redeemHistory'),
      "redirect": `/redeem/history`,
      "isOutSource": false,
    },
    {
      "title": t('topUpHistory'),
      "redirect": `/top-up/history`,
      "isOutSource": false,
    },
    {
      "title": t('language'),
      "redirect": '/language-setting',
      "isOutSource": false,
    },
    {
      "title": t('customerSupport'),
      "redirect": `https://tawk.to/chat/65129956b1aaa13b7a78fa32/1hb8aduju`,
      "isOutSource": true,
    },
    {
      "title": t('faq'),
      "redirect": `https://heewon.world/faq/`,
      "isOutSource": true,
    },
    {
      "title": t('privacy'),
      "redirect": `https://heewon.world/privacy/`,
      "isOutSource": true,
    },
    {
      "title": t('terms'),
      "redirect": `https://heewon.world/term-condition/`,
      "isOutSource": true,
    },
  ];

  function handleRedirect(redirect: string, isOutSource: boolean) {
    if (!isOutSource) {
      navigate(redirect);
    } else {
      window.open(redirect, "_blank", "noreferrer");
    }
  }

  return (
    <div>
      <Space direction="vertical" size="large" style={{ width:"100%" }}>
        <div>
          <Row style={{ justifyContent: "center"}}>
            <Avatar
              src={playerData?.profilePicUrl}
              size={80}
            />
          </Row>
          <Row style={{ justifyContent: "center"}}>
            <Text style={{ fontSize: "16px", fontWeight: 700 }}>
              { playerData?.playerName }
            </Text>
          </Row>
          <Row style={{ justifyContent: "center"}}>
            <Text style={{ fontSize: "14px", fontWeight: 500, color: "#8C8C8C" }}>
              {t('userId')}: { playerData?.uid }
            </Text>
          </Row>
        </div>
        <Row>
          <Card 
            style={{ 
              width: "100%",
              backgroundColor: "#FFF7E6",
            }} 
            bodyStyle={{  
              backgroundImage: `url(${heecoinIcon})`, 
              backgroundSize: "cover", 
              backgroundPosition: "center",
              height: "150px",
            }} 
            type="inner"
          >
            <div style={{ height: "100%", display: "grid", alignSelf: 'space-between' }}>
              <div>
                <Row>
                  <Text style={{ fontSize: "14px", fontWeight: 400, color: "#8C8C8C" }}>
                    {t('availableBalance')}
                  </Text>
                </Row>
                <Row>
                    <Text style={{ fontSize: "24px", fontWeight: 600, color: "#262626", marginRight: 5 }}>
                      { playerData?.heeCoin || 0 }
                    </Text>
                    <Text style={{ fontSize: "14px", fontWeight: 500, color: "#262626", lineHeight: 3 }}>
                      {t('heeCoin')}
                    </Text>
                </Row>
              </div>
              <div>
                <Row>
                  <Text style={{ fontSize: "12px", lineHeight: 6, fontWeight: 400, color: "#262626" }}>
                    <InfoCircleOutlined /> { heecoinRatio } {t('heeCoin')} = RM 1.00
                  </Text>
                </Row>
              </div>
            </div>
        </Card>
        </Row>
        <List
          style={{ width:"100%" }}
          dataSource={dataSetting}
          renderItem={(item) => (
            <List.Item style={{ padding: "8px 0" }}> 
              <Space direction="vertical" size="small" style={{ width:"100%" }}>
                <Row align="middle">
                  <Row style={{ width:"100%" }} onClick={() => handleRedirect(item.redirect, item.isOutSource)}>
                    <Col span={12}>
                      <Row>
                        <Text style={{ fontSize: "16px", fontWeight: 700, color: "#FFFFFF" }}>
                          {item.title}
                        </Text>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row style={{ justifyContent: "end" }}>
                        <Text style={{ fontSize: "16px", fontWeight: 700, color: "#FFFFFF" }}>
                            <RightOutlined />
                        </Text>
                      </Row>
                    </Col>
                    <Divider style={{ backgroundColor: "#262626", margin: "15px 0 0 0" }} />
                  </Row>
                </Row>
              </Space>
            </List.Item>
          )}
        />
      </Space>
    </div>
  );
};

export default Profile;
