import { z } from "zod";

export type TGeneral = {
  id: string;
  value: number;
};

export interface ConfigResults {
  results: Results[],
  page: number
  limit: number
  totalPages: number
  totalResults: number
}
type Results = {
  archive: boolean
  key: string
  value: number
  createdAt: string
  updatedAt: string
  id: string
}

export interface PlayerModel {
  uid: string
  playerId: string
  playerName: string
  petName: string
  profilePicUrl: string
  heeCoin: number
  hopeCoin: number
  heeCoinLastUpdated: string
  firstBonusAvail: boolean
}


export const ValidatePlayerSchema = z.object({
  password: z.string().min(5, "Password need to be more than 5 chars"),
});

export type ValidatePlayerModel = z.infer<typeof ValidatePlayerSchema>;