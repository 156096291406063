import Cookies from "js-cookie";
import { PlayerModel } from "../types/GenerateTypes";

interface UserSession {
  tokens: Token;
  player: TPlayer;
};

export type TPlayer = {
  id: string;
  uid: string;
  playerName: string;
  heeCoin: number
  hopeCoin: number
  heeCoinLastUpdated: string
  profilePicUrl: string
  petName: string
};

interface Token {
  access: TAccessToken;
  refresh: TRefreshToken;
}

type TAccessToken = {
  token: string;
  expires: string;
};

type TRefreshToken = {
  token: string;
  expires: string;
};


// save the token and user into the cookies and expired in 30 days
export const setAuthSession = ({ tokens, player }: UserSession) => {
  Cookies.set("user", JSON.stringify(player), { expires: 1 });
  Cookies.set("token", JSON.stringify(tokens), { expires: 1 });
  Cookies.set("accessToken", tokens.access.token, { expires: 1 });
  Cookies.set("userID", player.id, { expires: 1 });

  return true;
};

// remove the token and user from the cookies
export const removeAuthSession = () => {
  Cookies.remove("user");
  Cookies.remove("token");
  Cookies.remove("accessToken");
  Cookies.remove("userID");
  Cookies.remove("pathName");
};

// return the user data from the cookies
export const getUser = (): PlayerModel | null => {
  const userStr = Cookies.get("user");
  if (userStr) {
    const user = JSON.parse(userStr);

    if (!user.profilePicUrl) {
      user.profilePicUrl = 'https://heewon-assets.s3.ap-southeast-1.amazonaws.com/dummy450x450.jpg'
    }

    if (!user.playerName) {
      user.playerName = `Player ${user?.uid}`
    }
    return user;
  }
  return null;
};

export const setUser = ( player: PlayerModel ) => {
  Cookies.set("user", JSON.stringify(player), { expires: 1 });
};

export const deleteUserSession = () => {
  Cookies.remove("user");
  return true;
};

// return the token from the cookies
export const getToken = (): Token | null => {
  const token = Cookies.get("token");
  return token ? JSON.parse(token) : null;
};

// update and save the token into the cookies
export const setToken = (token: Token, expires: number) => {
  console.log("token: ", JSON.stringify(token));
  Cookies.set("token", JSON.stringify(token), { expires });
};

export const removeToken = () => {
  Cookies.remove("token");
  return true;
};

export const setAccessToken = (token: string, userID: string) => {
  console.log("accessToken: ", token, "userID: ", userID);
  Cookies.set("accessToken", token, { expires: 1 });
  Cookies.set("userID", userID, { expires: 1 });
};

export const getAccessToken = () => {
  const token = Cookies.get("accessToken");
  return token ? token : null;
};

export const getUserID = () => {
  const userID = Cookies.get("userID");
  return userID ? userID : null;
};

export const setPathName = (path: string) => {
  Cookies.set("pathName", path, { expires: 1 });
}

export const getPathName = () => {
  const path = Cookies.get("pathName");
  return path ? path : "redeem";
}

export const setLanguage = (language: string) => {
  Cookies.set("language", language, { expires: 1 });
}

export const getLanguage = () => {
  const language = Cookies.get("language");
  return language ? language : "en";
}

export const setHeeCoinRatio = (ratio: string) => {
  Cookies.set("heecoinRatio", ratio, { expires: 1 });
}

export const getHeeCoinRatio = () => {
  const ratio = Cookies.get("heecoinRatio");
  return ratio ? ratio : null;
}

export const setTopUpSession = (topupType: string, topupIcon: string, topupAmount: number, topupQty: number) => {
  Cookies.set("topupType", topupType, { expires: 1 });
  Cookies.set("topupIcon", topupIcon, { expires: 1 });
  Cookies.set("topupValue", String(topupAmount), { expires: 1 });
  Cookies.set("topupQty", String(topupQty), { expires: 1 });
}

export const getTopUpSession = () => {
  const topupType = Cookies.get("topupType");
  const topupIcon = Cookies.get("topupIcon");
  const topupValue = Cookies.get("topupValue");
  const topupQty = Cookies.get("topupQty");
  const topupSession = {
    type: topupType ? topupType : null,
    icon: topupIcon ? topupIcon : null,
    value: topupValue ? topupValue : null,
    quantity: topupQty ? topupQty : null
  }
  return topupSession;
}

export const removeTopUpSession = () => {
  Cookies.remove("topupType");
  Cookies.remove("topupIcon");
  Cookies.remove("topupValue");
  Cookies.remove("topupQty");
}


