import { useTranslation } from "react-i18next";

import { Typography, Col, Row, Space, Button, Divider } from "antd";
import { LeftOutlined } from '@ant-design/icons';

import { getUser } from "../../../utils/AuthService";

const ProfileAccount = () => {
  const { t } = useTranslation();
  const { Text } = Typography;
  
  const playerData = getUser();

  return (
    <div>
      <Space direction="vertical" size="middle" style={{ width:"100%" }}>
        <Row>
          <Col span={24}>
            <Button style={{ padding: 0 }} type="text" onClick={()=> history.back()}>
              <Text style={{ fontSize: "16px", fontWeight: 600, color: "#FFFFFF" }}>
                <LeftOutlined /> {t('account')}
              </Text>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
              <Text style={{ fontSize: "16px", color: "#FFFFFF" }}>
                {t('name')}
              </Text>
          </Col>
          <Col span={12} style={{ display: "flex", justifyContent: "end" }}>
              <Text style={{ fontSize: "16px", color: "#FFFFFF", fontWeight: 700 }}>
                { playerData?.playerName || `Player ${playerData?.uid}` }
              </Text>
          </Col>
        </Row>
        <Divider style={{ backgroundColor: "#262626", margin: 0 }} />
        <Row>
          <Col span={12} style={{ display: "flex" }}>
              <Text style={{ fontSize: "16px", color: "#FFFFFF" }}>
                {t('petName')}
              </Text>
          </Col>
          <Col span={12} style={{ display: "flex", justifyContent: "end" }}>
              <Text style={{ fontSize: "16px", color: "#FFFFFF", fontWeight: 700 }}>
                { playerData?.petName || '-' }
              </Text>
          </Col>
        </Row>
      </Space>
    </div>
  );
};

export default ProfileAccount;
