import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Typography, Form, Input, Button, message, Image, Space } from "antd";

import { zodResolver } from "@hookform/resolvers/zod";

import { setAuthSession, setLanguage, setPathName } from "../../utils/AuthService";
import { LoginModel, LoginSchema } from "./type/loginType";
import { login } from "./api/LoginAPI";

import loginBg from "../../assets/svg/login_background.svg";
import heewonLogo from "../../assets/svg/heewon.svg";
import loginFooter from "../../assets/svg/login-footer.svg";

const LoginPage = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const { Text } = Typography;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginModel>({
    resolver: zodResolver(LoginSchema),
  });

  const onSubmit = async (data: LoginModel) => {
    // Perform form submission logic here
    try {
      const loginAcc = await login(data);
      const userDetail = loginAcc.data;
      const successLogin = setAuthSession({
        tokens: userDetail.tokens,
        player: userDetail.player,
      });
      setPathName("redeem");
      setLanguage(i18n.language);
      successLogin === true ? navigate(0) : message.error("error");
    } catch (error) {
      const err = error as Error;
      message.error(err.message);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${loginBg})`, 
        padding: "0px 50px",
      }}
    >
      <div style={{ width: "100%" }}>
        <Space direction="vertical" size="large" style={{ width: "100%" }} >
          <div >
            <h1>{t('welcomeBack')}</h1>
            <h4>{t('loginHeader')}</h4>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }} >
            <Image src={heewonLogo} width="35%" preview={false} />
          </div>

          <Form
            name="loginForm"
            layout="vertical"
            onFinish={handleSubmit(onSubmit)}
            labelAlign="left"
            colon={false}
          >
            <Form.Item
              name="phone"
              label={<label style={{ fontSize:"16px", color:"white" }}>{t('phoneNumber')}</label>}
              hasFeedback
              validateStatus={errors.phone ? "error" : ""}
              help={errors.phone?.type === "too_small" ? t('errorMinPhoneNumber') : errors.phone?.type === "too_big" ? t('errorMaxPhoneNumber') : errors.phone?.type === "invalid_type" ? t('required') : null}
            >
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <Input prefix={"+60"} size="large" style={{padding: "15px", fontSize: "11px"}} placeholder={t('hintPhoneNumber')} {...field} />
                )}
              />
            </Form.Item>
            <Form.Item
              name="password"            
              label={<label style={{ fontSize:"16px", color:"white" }}>{t('password')}</label>}
              hasFeedback
              validateStatus={errors.password ? "error" : ""}
              help={errors.password?.type === "too_small" ? t('errorPassword') : errors.password?.type === "invalid_type" ? t('required') : null}
            >
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <Input.Password size="large" style={{padding: "15px", fontSize: "11px"}} placeholder={t('hintPassword')} {...field} />
                )}
              />
            </Form.Item>

            <Form.Item style={{ width: "100%", marginTop: 30  }}>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="default-button"
              >
                <Text style={{fontSize: "18px", fontWeight: 700, color: "#FFFFFF",}}>
                  {t('login')}
                </Text>
              </Button>
            </Form.Item>
          </Form>
        
          <Image width="100%" src={loginFooter}  preview={false} />
        </Space>
      </div>
    </div>
  );
};

export default LoginPage;
