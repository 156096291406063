import { useTranslation } from "react-i18next";

import moment from 'moment';
import { useQuery } from "@tanstack/react-query";

import { Typography, Col, Row, Space, List, Button, Divider, message } from "antd";
import { LeftOutlined } from '@ant-design/icons';

import { getTopupHistory } from "../api/TopupAPI";
import { TopupHistoryModel } from "../type/topupType";
import { getUserID } from "../../../utils/AuthService";

const TopUpHistory = () => {
  const { t } = useTranslation();
  const { Text } = Typography;

  const playerId = getUserID();

  const { 
    data: topupHistories, 
    isLoading, 
    isError,
  } = useQuery<TopupHistoryModel, Error>({
    queryKey: ["topupHistory", playerId],
    queryFn: () => getTopupHistory(playerId!),
    retry: false,
    onError: (error) => {
      message.error(error.message);
    },
  });

  return (
    <div>
      <Space direction="vertical" size="middle" style={{ width:"100%" }}>
        <Row>
          <Col span={24}>
            <Button style={{ padding: 0 }} type="text" onClick={()=> history.back()}>
              <Text style={{ fontSize: "16px", fontWeight: 600, color: "#FFFFFF" }}>
                <LeftOutlined /> {t('topUpHistory')}
              </Text>
            </Button>
          </Col>
        </Row>
        <Row align="middle">
          <List
            style={{ width:"100%" }}
            loading={isLoading}
            dataSource={topupHistories?.results}
            renderItem={(item) => (
              <List.Item>
                <Row style={{ width:"100%" }}>
                  <Col span={12}>
                    <Row>
                      <Text style={{ fontSize: "16px", fontWeight: 600, color: "#FFFFFF" }}>
                        {t('topUp')} RM{item.item.price.toFixed(2)}
                      </Text>
                    </Row>
                    <Row>
                      <Text style={{ fontSize: "14px", color: "#8C8C8C" }}>
                        {t('transactionId')}: {item._id}
                      </Text>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row style={{ justifyContent: "end" }}>
                      <Text style={{ fontSize: "16px", fontWeight: 700, color: "#FFFFFF" }}>
                          +{item.item.effect} {t('hopeCoin')}
                      </Text>
                    </Row>
                    <Row style={{ justifyContent: "end" }}>
                      <Text style={{ fontSize: "14px", color: "#8C8C8C" }}>
                        {moment(item.createdAt).format('DD/MM/YYYY, h:mma')}
                      </Text>
                    </Row>
                  </Col>
                  <Divider style={{ backgroundColor: "#262626", margin: "20px 0 0 0" }} />
                </Row>
              </List.Item>
            )}
          />
        </Row>
      </Space>
    </div>
  );
};

export default TopUpHistory;
